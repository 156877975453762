import React, {useEffect, useState} from 'react';
import {IconFA} from 'components/Icons';
import {ContentTable} from 'components/Contents';
import {
  Alert,
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import {get} from 'lodash';
import {getFormattedNumber, getFormattedPrice} from 'utils/numbers';
import {LoadingPage} from 'components/Loading';
import {
  SUBSCRIPTION_PLANS_TYPE,
  SUBSCRIPTION_PLANS_TYPE_LABEL,
} from 'configs/subscription';
import {useSubscription} from 'contexts/SubscriptionContext';
import {getFutureDate} from 'utils/dates';
import {useHistory, useLocation} from 'react-router';

function TableHead({label}) {
  return <small className="text-secondary">{label}</small>;
}

function PricingBreakdownModal(props) {
  const {
    openBreakdownModal,
    priceBreakdownLoading,
    toggleBreakdownModal,
    priceBreakdown,
    onUpgrade,
    isSubscribing,
  } = props;

  const {pathname} = useLocation();
  const history = useHistory();
  const {isSubscribed, isFree} = useSubscription();
  const [isChecked, setIsChecked] = useState(false);

  const head = [
    <TableHead label="Description" />,
    <TableHead label="Qty" />,
    <TableHead label="Amount" />,
  ];

  const {
    planType,
    total,
    remaining_verifications,
    additional_user_seats,
    remaining_time,
    upgrade_plan,
    trial_duration,
  } = priceBreakdown || {};

  const isFreeTrial = planType === 'free_trial';

  useEffect(() => {
    if (openBreakdownModal) {
      setIsChecked(false);
    }
  }, [openBreakdownModal]);

  async function toggleClose() {
    history.push(pathname);
    toggleBreakdownModal();
  }

  return (
    <Modal
      toggle={toggleClose}
      isOpen={openBreakdownModal}
      centered
      className="notranslate"
      style={{zIndex: '1001'}}
    >
      <ModalHeader toggle={toggleClose}>
        {isFreeTrial && isFree && 'Confirm Free Trial'}
        {!isFreeTrial && 'Confirm Upgrade'}
      </ModalHeader>
      <ModalBody>
        {priceBreakdownLoading && <LoadingPage isLoading />}
        {isSubscribed &&
          planType !== SUBSCRIPTION_PLANS_TYPE.premium &&
          !priceBreakdownLoading && (
            <Alert color="primary">
              <div className="d-flex align-items-center flex-row">
                <IconFA name="info-circle" className="mr-3" />
                <p className="mb-0">
                  Consumed Verifications will be deducted from your new
                  verification credits.
                </p>
              </div>
            </Alert>
          )}

        <ContentTable head={head} className="mb-0" isBreakdownTable>
          <tr>
            <td className="align-middle">
              {isFreeTrial && 'Basic Plan (Free Trial)'}
              {!isFreeTrial && (
                <>{SUBSCRIPTION_PLANS_TYPE_LABEL[planType]} Plan</>
              )}
            </td>
            <td className="align-middle">{get(upgrade_plan, 'quantity')}</td>
            <td className="align-middle text-right px-2">
              {isFreeTrial && (
                <>
                  <p className="mb-0">
                    {getFormattedNumber(trial_duration)} days free
                  </p>
                  <small className="text-secondary">
                    then {getFormattedPrice(get(upgrade_plan, 'amount'))} / year
                  </small>
                </>
              )}
              {!isFreeTrial && getFormattedPrice(get(upgrade_plan, 'amount'))}
            </td>
          </tr>

          {!!get(remaining_verifications, 'quantity') &&
            !!get(remaining_verifications, 'amount') && (
              <tr>
                <td>Remaining Verifications</td>
                <td>
                  {getFormattedNumber(get(remaining_verifications, 'quantity'))}
                </td>
                <td className="text-right px-2">
                  -{getFormattedPrice(get(remaining_verifications, 'amount'))}
                </td>
              </tr>
            )}

          {!!get(additional_user_seats, 'quantity') && (
            <tr>
              <td>Additional User Seats</td>
              <td>
                {getFormattedNumber(get(additional_user_seats, 'quantity'))}
              </td>
              <td className="text-right px-2">
                {getFormattedPrice(get(additional_user_seats, 'amount'))}
              </td>
            </tr>
          )}

          {get(remaining_time, 'quantity') !== 0 &&
            get(remaining_time, 'amount') !== 0.0 && (
              <tr>
                <td>Remaining Time</td>
                <td>{get(remaining_time, 'quantity')} months</td>
                <td className="text-right px-2">
                  -{getFormattedPrice(get(remaining_time, 'amount'))}
                </td>
              </tr>
            )}
        </ContentTable>
        <hr className="m-0" />
        <div className="d-flex flex-row align-items-center m-2 mb-3 my-3">
          <h4 className="flex-grow-1 mb-0">Total</h4>
          <h4 className="mb-0">{getFormattedPrice(isFreeTrial ? 0 : total)}</h4>
        </div>
        {!isFreeTrial && !priceBreakdownLoading && (
          <small className="text-danger mx-2">
            You will be charged once you proceed with upgrading your plan.
          </small>
        )}

        <div className="mt-3 mx-2">
          <CustomInput
            id="terms-agreement"
            checked={isChecked}
            disabled={isSubscribing}
            onChange={() => setIsChecked(!isChecked)}
            type="checkbox"
            label={
              <small>
                I acknowledge and accept the{' '}
                <a href="/policies/subscription-terms">
                  Subscription Terms and Agreement
                </a>
              </small>
            }
          />
        </div>

        {isFreeTrial && (
          <div className="mt-2 mx-2">
            <small className="text-secondary" style={{fontSize: '10px'}}>
              Your 14-day free trial starts today. To avoid charges cancel your
              subscription under Membership & Billing by {getFutureDate(14)}.
              Please note, we cannot issue refunds once your card is charged.
            </small>
          </div>
        )}

        <div className="d-flex flex-column m-2 mt-3" style={{gap: '8px'}}>
          <Button
            color={isSubscribing ? 'light' : 'primary'}
            onClick={onUpgrade}
            disabled={isSubscribing || !isChecked}
          >
            {isFreeTrial && isFree && (
              <>
                {isSubscribing && (
                  <IconFA name="spinner" className="fa-spin mr-1" />
                )}
                Confirm Free Trial
              </>
            )}

            {!isFreeTrial && (
              <>
                {isSubscribing && 'Processing Payment'}
                {!isSubscribing && 'Confirm Upgrade'}
              </>
            )}
          </Button>
          <Button color="white" onClick={toggleClose} disabled={isSubscribing}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default PricingBreakdownModal;
