import {getConfirmTransaction} from 'api/company-list';
import {postVerifyCeCerts, postVerifyCerts} from 'api/import-management';
import {createContext, useContext, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {alertByError} from 'utils/alerts';
import {useToast} from './ToastContext';
import {useLimits} from './LimitContext';
import {useLockedFeature} from './LockedFeatureContext';
import {useSubscription} from './SubscriptionContext';
import {useGlobalCounts} from './GlobalCountContext';

const message = 'Company list and certifications count successfully updated.';

const VerificationContext = createContext({});

function VerificationProvider(props) {
  const {children} = props;
  const history = useHistory();
  const {activityId} = useParams();
  const {toastSuccess} = useToast();
  const {getLimit} = useLimits();
  const {isSubscribed, isFreeTrial} = useSubscription();
  const {toggleLock} = useLockedFeature();
  const {getGlobalCounts} = useGlobalCounts();

  const [isOpen, setIsOpen] = useState(false);
  const toggleClose = () => setIsOpen((prev) => !prev);

  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmData, setConfirmData] = useState({});

  // placeholder for verification action
  const [type, setType] = useState('');

  const [isVerifying, setIsVerifying] = useState(false);
  const {verifyId} = confirmData || {};

  async function confirmVerification(ids) {
    const {bm_upload_activity_id, cl_id, cli_ids, cert_ids} = ids || {};
    setIsConfirming(true);

    try {
      const data = await getConfirmTransaction(cl_id, {
        cli_ids: cli_ids,
        mdbcert_ids: cert_ids,
        bm_upload_activity_id,
      });

      setIsConfirming(false);
      setConfirmData({
        ...data,
        verifyId: bm_upload_activity_id,
        cli_ids,
        cert_ids,
      });
    } catch (e) {
      alertByError(e);
    }
  }

  function toggleVerifyModal(ids, type) {
    if (!isSubscribed && !isFreeTrial) {
      return toggleLock('verification');
    }

    setType(type);
    setIsOpen(!!ids);
    confirmVerification(ids);
  }

  async function onVerify() {
    setIsVerifying(true);
    try {
      const {data} = await postVerifyCeCerts(0, {
        bm_upload_activity_id: verifyId,
      });

      const {status} = data || {};

      if (status === 'queued') {
        toggleVerifyModal();
      }

      if (status === 'success') {
        toggleClose();
      }

      getLimit();
      getGlobalCounts();
      history.push('/company-list');
    } catch (e) {
      alertByError(e);
    } finally {
      setIsVerifying(false);
    }
  }

  async function onVerifyCompany(callback) {
    setIsVerifying(true);
    try {
      const {cli_ids, verifyId} = confirmData || {};

      const {data} = await postVerifyCeCerts(0, {
        company_list_item_ids: cli_ids,
        bm_upload_activity_id: verifyId || activityId,
      });

      const {status} = data || {};

      if (status === 'success') {
        toastSuccess({
          title: message,
          position: 'top-right',
          body: null,
          date: null,
        });
      }

      getLimit();
      getGlobalCounts();
      callback && callback(status);

      toggleVerifyModal();
    } catch (e) {
      alertByError(e);
    } finally {
      setIsVerifying(false);
    }
  }

  async function onVerifyCerts(callback) {
    setIsVerifying(true);

    const {cli_ids, cert_ids} = confirmData || {};

    try {
      const {data} = await postVerifyCerts(0, {
        certification_ids: cert_ids,
        company_list_item_ids: verifyId || cli_ids,
        bm_upload_activity_id: activityId,
      });

      const {status} = data || {};

      if (status === 'success') {
        toastSuccess({
          title: message,
          position: 'top-right',
          body: null,
          date: null,
        });
      }

      getLimit();
      getGlobalCounts();
      callback && callback(status);

      toggleVerifyModal();
    } catch (e) {
      alertByError(e);
    } finally {
      setIsVerifying(false);
    }
  }

  return (
    <VerificationContext.Provider
      value={{
        type,
        onVerify,
        toggleClose,
        toggleVerifyModal,
        confirmData,
        isConfirming,
        isVerifying,
        isOpen,
        onVerifyCompany,
        onVerifyCerts,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
}

const useVerification = () => useContext(VerificationContext);

export {VerificationContext, VerificationProvider, useVerification};
