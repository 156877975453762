import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {isNumber, toNumber, isNaN} from 'lodash/lang';

export const setDayJs = () => {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
};

export const date = (value, format = 'YYYY-MM-DD') => {
  let result;

  if (!value) return 'N/A';

  //  toNumber()
  //  1540262852 -> 1540262852
  //  '1540262852' -> 1540262852
  //  '2009-12-12' -> NaN

  let numberValue = toNumber(value);

  if (!isNumber(numberValue) || isNaN(numberValue)) {
    result = dayjs(value).format(format);
  } else if (isNumber(numberValue) && format === 'fromNow') {
    result = dayjs(numberValue * 1000).fromNow();
  } else if (isNumber(numberValue)) {
    result = dayjs(numberValue * 1000).format(format);
  }

  return result;
};

export const padTimeUnit = (unit = '', padding = 2) =>
  unit.toString().padStart(padding, '0');

export function getRemainingTime(remainingSeconds) {
  const hours = padTimeUnit(Math.floor(remainingSeconds / 3600));
  const minutes = padTimeUnit(Math.floor((remainingSeconds % 3600) / 60));
  const seconds = padTimeUnit(remainingSeconds % 60);

  return `${hours}:${minutes}:${seconds}`;
}

export const getExpiration = (value, expiration = 1, unit = 'days') => {
  if (!value) return 'N/A';

  const currentTime = dayjs();
  const startTime = dayjs.unix(value);

  // set the expiration date based from the returned value
  const expirationTime = startTime.add(expiration, unit);
  const remainingSeconds = expirationTime.diff(currentTime, 'second');

  return getRemainingTime(remainingSeconds);
};

export function getFutureDate(daysFromNow) {
  // Use the current date as the base date
  const baseDate = dayjs();

  // Add the specified number of days
  const futureDate = baseDate.add(daysFromNow, 'day');

  // Format the date
  return futureDate.format('MMMM D, YYYY');
}
