import {useState} from 'react';
import {alertByError} from 'utils/alerts';
import {getMembershipBreakdown} from 'api/membership';
import {SUBSCRIPTION_PLANS_TYPE} from 'configs/subscription';

function useGetPriceBreakdown() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  async function getPriceBreakdown(planType) {
    setIsLoading(true);
    try {
      const {data} = await getMembershipBreakdown({
        subscription_plans_type:
          planType === 'free_trial' ? SUBSCRIPTION_PLANS_TYPE.basic : planType,
        free_trial: planType === 'free_trial',
      });

      setData({...data, planType});
    } catch (e) {
      alertByError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    priceBreakdown: data,
    getPriceBreakdown,
    priceBreakdownLoading: isLoading,
  };
}

export default useGetPriceBreakdown;
