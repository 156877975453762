import routes from 'routes';
import {isEmpty} from 'lodash';
import {ROUTE_ACCESS_RULES} from 'configs';

const EXCEPTION_ROUTES = ['/setup', '/consents', '/logout'];

function useGetRoutes(routeAccess = 'public') {
  function recurseRoutes(values) {
    const {routes = []} = values || {};
    let result = [];

    routes.forEach((r) => {
      const {path, routes, access} = r;

      access === ROUTE_ACCESS_RULES[routeAccess] && result.push(path);
      if (!isEmpty(routes)) {
        result = [...result, ...recurseRoutes({routes: routes})];
      }
    });

    return result;
  }

  function getRoutes() {
    let payload = {routes};
    return [...new Set(recurseRoutes(payload))].filter(
      (path) => !EXCEPTION_ROUTES.includes(path)
    );
  }

  return getRoutes();
}

export default useGetRoutes;
